import styled from 'styled-components'

import { hexToRgba, lightOrDark, lightOrDark1 } from '../HexToRGBA'

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const AudioPlayerContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-content: center;
    flex: 1 1 100%;
    height: 15%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(8px);
    position: fixed;
    z-index: 100;
    padding:3%;
    overflow: hidden;
    border-top: groove 3px ${props => hexToRgba(props.theme.mainColor)}
    background: ${props => hexToRgba(props.theme.mainBg, 0.6)}
    border-radius: 5px;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        height: 12vh;
      }
`
export const AudioPlayerWrapper = styled.div`
    display: grid; 
    justify-content: flex-start;
    align-items: center;
    color: ${ props => props.theme.secondaryBg};
    background-color: ${ props => hexToRgba(props.theme.accentColor,0.1)};
    width: 100%;
    max-width: 90rem;
    grid-area: 1/2/2/2;
    justify-self: start;
    overflow: hidden;
    margin: 0 2%;
    grid-template-columns: auto 1fr;
    box-shadow: inset 0 0 0 0.5px ${ props => hexToRgba(props.theme.secondaryBg,0.2)};
    border-radius: 3px;

`

export const Audio = styled.audio`

`

export const  AudioPlayerCoverArtWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0;
    margin: 0;
    cursor: pointer;
    z-index: 10;
    width: 66px;
    height: 66px;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        width: 44px;
        height: 44px;
      }
      @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        width: 30px;
        height: 30px; 
        transition: 0.8s all ease;
        
      } 
    
`

export const  AudioPlayerCoverArt = styled.div`
    display: flex;
    background: url(${props => props.src});
    background-size: cover;
    width: 100%;
    height: 100%;
`

export const AudioPlayerDetailsWrapper = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
`

export const AudioPlayerSongDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    text-align: center;
    padding: 2% 0;
    color: ${ props => props.theme.secondaryBg};
  
    flex-direction: column;
    cursor: pointer;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        font-size: 0.5rem;
        padding: 3% 0;
      }
      @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        transition: 0.8s all ease;
        font-size: 0.35rem;
        padding: 3% 0;
        
      } 
`

export const AudioPlayerSongDetailsText = styled.span`
    display: inline-block;
    text-align: start;
    align-items: center;
    padding-left: 1%;
    white-space: nowrap;
    -webkit-padding-end: 10px;
    padding-inline-end: 10px;
    transition: 0.8s all ease;
    
`

export const AudioPlayerDurationTimerWrapper = styled.div`
    margin-top: 1%;
    display: flex;
    text-align: start;
    width: 100%;
    flex: 1 1 100%;
    align-items: center;
    font-size: 0.6rem;
    justify-content: space-between;
    transition: 0.8s all ease;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        font-size: 0.4rem;
        justify-content: space-evenly;
      }
      @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        justify-content: space-evenly;
        transition: 0.8s all ease;
        font-size: 0.35rem;
      } 

`


export const AudioPlayerTimerText = styled.small`
      display: flex;
      padding: 0 2%;
      cursor: pointer;

`

export const AudioPlayerSliderWrapper = styled.div`
    display: flex;
    width: 100%;
`

export const AudioPlayerSlider = styled.input`
    
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
    padding: 0;
    width: 100%;
    flex: 1 1 100%;
    height: 3px;
    background-color:  ${ props => ((lightOrDark(props.theme.secondaryBg) === "dark" && lightOrDark(props.theme.mainColor) === "dark") || (lightOrDark(props.theme.secondaryBg) === "light" && lightOrDark(props.theme.mainColor) === "light")) ? props.theme.mainBg : props.theme.secondaryBg};
    border: 0;
    cursor: pointer;
    color: ${ props => props.theme.mainColor};
    position: relative;
    direction: ltr;

    &:before{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      justify-self: center;
      content: '';
      height: 3px;
      overflow: hidden;
      width: ${props => props.progressWidth};
      z-index: 1;
      background-color: ${props => props.theme.mainColor};
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        height: 0.21rem; 
        
      }
      @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        transition: 0.8s all ease;
        height: 0.21rem;
          
        } 
    }
    &:after{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 4px;
      width: ${props => props.bufferWidth};
      z-index: 2;
      background-color: ${props => hexToRgba(props.theme.mainBg, 0.5)};
   
    }


    //Chrome, Edge, Safari settings
    ::-webkit-slider-runnable-track{
      background-color:  ${ props => ((lightOrDark(props.theme.secondaryBg) === "dark" && lightOrDark(props.theme.mainColor) === "dark") || (lightOrDark(props.theme.secondaryBg) === "light" && lightOrDark(props.theme.mainColor) === "light")) ? props.theme.secondaryColor : props.theme.secondaryBg};
      color: ${ props => props.theme.mainColor};
      width: 100%;
   border-radius: 0.5rem;
   height: 3px;
   overflow: hidden;
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 0.2rem; 
    
  } 
    }

    ::-webkit-slider-thumb{
        -webkit-appearance: none; /* Override default look */
   appearance: none;
   background-color: ${(props) => hexToRgba(props.theme.accentColor,1)};
   z-index: 100;
   color: ${ props => props.theme.mainColor};

   height: 1rem;
   width: 0.5rem;
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 0.23rem; 
    background-color: ${(props) => hexToRgba(props.theme.accentColor,0)};
    color: ${ props => hexToRgba(props.theme.mainColor,0)};
    
  }
    }

    //Firefox settings
    ::-moz-range-track{
      background-color:  ${ props => ((lightOrDark(props.theme.secondaryBg) === "dark" && lightOrDark(props.theme.mainColor) === "dark") || (lightOrDark(props.theme.secondaryBg) === "light" && lightOrDark(props.theme.mainColor) === "light")) ? props.theme.mainBg : props.theme.secondaryBg};
      color: ${ props => props.theme.mainColor};

   border-radius: 0.5rem;
   height: 3px; 
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 0.2rem;  
    
  }
    }

    ::-moz-range-thumb{
        border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: ${(props) => hexToRgba(props.theme.accentColor,1)};    
    height: 1rem;
    width: 0.5rem;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
      transition: 0.8s all ease;
      height: 0.23rem;  
      color: ${ props => hexToRgba(props.theme.mainColor,0)};
        
      }
    }
    &:hover {
      border-radius: 50px;
      transition: all 0.3s ease-in-out;
      color: ${(props) => props.theme.mainColor};
    }
    @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
      transition: 0.8s all ease;

      height: 0.2rem;
        
      } 

`

export const AudioPlayerControlsWrapper = styled.div`
      display: flex;
      justify-content: center;
      width: 100%;
      flex: 1 1 100%;
      text-align: center;
      box-sizing: border-box;
      vertical-align: top;

`
export const AudioPlayerPlaybackControlsWrapper = styled.div`

align-items: center;
display: grid;
grid-template-columns: ${props => props.isPodcast ? `repeat(5,1fr)`: `repeat(3,1fr)`};

`


export const AudioPlayerControls = styled.div`
display: flex;
width: 30px;
align-items: center;
height: 22px;
font-size: 1rem;
border-radius: 4px;
cursor: pointer;
color: ${props => props?.isActive ? props.theme.accentColor : props.theme.secondaryBg};
@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    width: 20px;
    height: 12px;
    font-size: 1rem;
  }

  &:hover{
    transform: scale(1.07);
    transition: 0.8s ease-out;
}
`

export const VolumeControlsWrapper = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: ${ props => props.theme.secondaryBg};
    
      


`

export const AudioPlayerVolumeSlider = styled.input`

    padding: 0;
    width: 70px;
    height: 3px;
    display: inline-block;
    justify-self: center;
    align-self: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: visible;
    background-color: ${ props => props.theme.secondaryBg};
    border: 0;
    cursor: pointer;
    color: ${(props) => props.theme.secondaryColor};

    ::-webkit-slider-thumb{
        -webkit-appearance: none; /* Override default look */
   appearance: none;
   border-radius: 50%;
   z-index: 10;
   background-color: ${(props) => props.theme.mainBg};
   border: solid 1px ${(props) => props.theme.accentColor};
   height: 0.7rem;
   width: 0.7rem;
    }
    &:before{
      
      position: absolute;
      z-index: 1 ;
      justify-self: center;
      content: '';
      height: 3px;
      overflow: hidden;
      width: ${props => props.volume};
      
      background-color: ${props => props.theme.mainColor};
    }
    ::-moz-range-thumb{
    z-index: 10;
        border: none; /*Removes extra border that FF applies*/
    border-radius: 50%; /*Removes default border-radius that FF applies*/
    border-radius: 50%;
    border: solid 1px ${(props) => props.theme.accentColor};
    background-color: ${(props) => props.theme.mainBg};
  height: 0.7rem;
   width: 0.7rem;
    }




${VolumeControlsWrapper}: hover &{
      transition: all 0.8s ease-in-out;
      color: ${(props) => props.theme.mainColor};
    }
`

export const PreviewBadge = styled.div`
display: flex;
background-color: ${props => props.theme.mainColor};
font-size: 0.5rem;
line-height: 1.5;
font-weight: 700;
width: fit-content;
letter-spacing: 0em;
margin-top: auto;
margin-left: 3%;
margin-bottom: 4px;
padding 0 4px;
grid-area: badge;
z-index: 1;
color: ${props => lightOrDark1(props.theme.mainColor) === 'light' ? 'black' : 'white'};
white-space: nowrap;
border-radius: 3px;
justify-self: flex-end;

@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    font-size: 0.3rem;
  }

`


