import React, { useEffect, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { useSonarMusePlayer } from '../../hooks/AudioHook'
import { LyricLine } from './LyricSynchronizerElements'
import { MusicPlayPauseButton } from '../MusicPage/MusicPageElements'
import { ButtonWrapper } from '../Profile/ProfileDetails/ProfileDetailsElements'
import { addSyncLyrics } from '../../utils/dbUtils/musicUtils'
import { useAlert } from '../Alert/AlertContext'
import { ALERT_TYPES } from '../../utils/Enum'


function useCurrentLine(lyrics,timestamp) {
    const [currentLine, setCurrentLine] = useState(null);
  
    useEffect(() => {
        console.log(lyrics)
      const nextLineIndex = lyrics.findIndex(
        (line) => line.startTime > timestamp
      );
  
      if (nextLineIndex === 0) {
        setCurrentLine(0);
      }
      else{
       setCurrentLine(nextLineIndex - 1); 
      }
  
      
    }, [timestamp]);
  
    return { currentLine };
  }


const LyricSynchronizer = ({song, currentTime, togglePlayPause, isPlaying, timeTravel}) => {
    const {currentLine} = useCurrentLine(song?.syncedLyrics, currentTime)
    const currentLineRef = useRef(null);
    useEffect(() => {
        if (currentLineRef.current) {
          currentLineRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      
      /*    */
      }, [currentLine]);
      


      
  return (
    <>
     

    <ContentWrapper>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 100%', maxHeight: '100vh', overflowY: 'auto', width: '100%', alignItems: 'center'}}>
       
        {song?.syncedLyrics?.map( (line, index) => {
            const isCurrentLine = index === currentLine
            const isPastLine = line?.startTime < currentTime;
        return (
          <><LyricLine 
          onClick={() => {
            !isPlaying && togglePlayPause()
            timeTravel(line?.startTime)

          }}
          ref={isCurrentLine ? currentLineRef : null}
          isActive={isCurrentLine}
          isPastLine={isPastLine}
          key={index}>
            {line?.line}
          </LyricLine>
          <br/></>
        );
      })}
      </div>
    </ContentWrapper>
    
    
    </>
  )
}

export default LyricSynchronizer