import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const LyricLine = styled.p`
    display: flex;
    font-size: 2rem;
    cursor: pointer;
    color: ${props => props.isActive ? props?.theme?.accentColor : props?.isPastLine ? hexToRgba(props?.theme?.secondaryColor, 0.3) : props?.theme?.secondaryBg};


`